
import { defineComponent, PropType } from 'vue';
import AnswerCardListItem from '@/views/AnswerCard/AnswerCardList/AnswerCardItem/AnswerCardListItem.vue';
import { OmrAnswerCard, StudentAnswer } from '@/services/api/models/OmrAnswerCard';
import { IonCheckbox } from "@ionic/vue";
import { ExerciseVariant, GtTest } from '@/models/gt-test';
import { allAnswersMarkedExerciseArr } from '@/utils/DataUtils';

export default  defineComponent({
  name: 'AnswerCardList',
  components: { AnswerCardListItem, IonCheckbox },
  props: {
    answerKeys: {
      type: Array as PropType<GtTest[]>,
      default: () => []
    },
    cardList: {
      type: Array as PropType<OmrAnswerCard[]>,
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    isPointerVisible: {
      type: Boolean,
      default: false
    },
    isMultipleFiles: {
      type: Boolean,
      default: false
    },
    lastCheckedCard: {
      type: Object as PropType<OmrAnswerCard>
    },
    parentElement: {
      type: Object as any
    }
  },
  methods: {
    onAnswerCardModalOpen (id: number): void {
      this.$emit('onAnswerCardModalOpen', id);
    },
    isListItemHighlighted(card: OmrAnswerCard) {
      return card.id === this.lastCheckedCard?.id && !this.isPreview;
    },
    changeStudentNumber(event: Event) {
      this.$emit('changeStudentNumber', event );
    },
    isAsterixVisible(card: OmrAnswerCard) {
      return card.score.answer.some((item: StudentAnswer) => item.isChanged);
    },
    getCurrentAnswerKey(card: OmrAnswerCard) {
      return this.answerKeys.find((item: GtTest) => item.hash === card.test.hash);
    },
  },
  data () {
    return {
      allAnswersMarkedExerciseArr: allAnswersMarkedExerciseArr
    }
  },
  updated () {
    const lastCheckedCardIsNotEmpty = Object.keys(this.lastCheckedCard as OmrAnswerCard).length > 0;
    const listItem = document.getElementById(`listItem-${this.lastCheckedCard?.id}`);
    if (!this.isMultipleFiles && lastCheckedCardIsNotEmpty) {
      const position = listItem?.offsetTop as number - 300;
      this.parentElement.$el.scrollToPoint(null, position, 500);
    }
    listItem?.addEventListener('animationend', () => {
      this.$emit('clearLastCheckedCard');
      listItem?.classList.remove('gt-scores-answer-card-list-item--success');
    });
  }
});
