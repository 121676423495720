export const getColor = (score: number): string => {
    switch (true) {
        case score > 0 && score <= 29:
            return '#EB5757';
        case score >= 30 && score < 50:
            return '#F08B4A';
        case score >= 50 && score < 70:
            return '#69D06B';
        case score >= 70 && score < 85:
            return '#2BABF3';
        case score >= 85 && score < 95:
            return '#067EB4';
        case score >= 95 && score <= 100:
            return '#184077';
        default:
            return '';
    }
}
