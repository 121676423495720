import { AnswersType, ExerciseAnswer, ExerciseAnswerKey, ExerciseVariant, GtTest } from '@/models/gt-test';
import { AnswerTypeString, OmrAnswerCard } from '@/services/api/models/OmrAnswerCard';
import { ErrorFiles, OmrErrorResponseTextEnum } from '@/models';

export enum CategoryInfoLevel {
    EDUCATION_LEVEL = 1,
    SUBJECT,
    METHOD,
    BOOKYEAR
}

export const getCurrentVariant = (variants: ExerciseVariant[], variantId: number): ExerciseVariant => {
    return variants.find(variant => variant.variantId === variantId) as ExerciseVariant;
}

export const getAnswerTypeEnumFromString = (answerType: AnswerTypeString): AnswersType => {
    const answersTypeMap: {[key in AnswerTypeString]: AnswersType} = {
        'assignment': AnswersType.ASSIGNMENT,
        'bundles': AnswersType.BUNDLES,
        'justification': AnswersType.JUSTIFICATION,
        'multiple-choice': AnswersType.MULTIPLE_CHOICE,
        'single-choice': AnswersType.SINGLE_CHOICE,
        'true-choice': AnswersType.BOOLEAN,
        'open': AnswersType.OPEN
    }
    return answersTypeMap[answerType]
}

export const isExerciseOld = (answersType: AnswersType): boolean => {
    return answersType === AnswersType.OPEN ||
      answersType === AnswersType.CLOSED ||
      answersType === AnswersType.OPEN_CLOSED;
}

export const generateHash = (text: string): string => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        const char = text.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash).toString();
}

export const categoryInfo = (categoryKey: string, level: CategoryInfoLevel): string => {
    return categoryKey.split('/')[level];
};

export const groupFilesByError = (errorFiles: ErrorFiles[] = [], fileName: string, error: OmrErrorResponseTextEnum): ErrorFiles[] => {
    if (error === OmrErrorResponseTextEnum.UNAUTHORIZED_REQUEST) {
        return errorFiles;
    }
    const existingError = errorFiles.find(item => item.errorType === error);
    if (existingError) {
        existingError.files.push(fileName);
    } else {
        errorFiles.push({
            errorType: error,
            files: [fileName]
        });
    }
    return errorFiles;
}

export const deepClone = (obj: object): any => {
    return JSON.parse(JSON.stringify(obj));
};

export const downloadBlobFile = (fileName: string, blob: Blob): void => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export const numbersArrayFrom = (length: number): number[] => {
    return Array.from(Array(length).keys());
}

export const allAnswersMarkedExerciseArr = (card: OmrAnswerCard, answerKey: GtTest): number[] => {
    const arr: number[] = [];
    const currentAnswerKeyVariant: ExerciseVariant = answerKey.variants.find((item: ExerciseVariant) => item.variantId === card.test.variantId) as ExerciseVariant;
    currentAnswerKeyVariant.exercise.forEach((item: ExerciseAnswerKey, exerciseIndex: number) => {
        const isAllAnswersMarked = item.answersType === AnswersType.MULTIPLE_CHOICE && item.answers?.length === (card.score.answer[exerciseIndex].answers[0] as number[]).length;
        const isAllAnswersInAnswerKeyTrue = currentAnswerKeyVariant.exercise[exerciseIndex].answers?.every((answer: ExerciseAnswer) => answer.isCorrect);
        if (isAllAnswersMarked && !isAllAnswersInAnswerKeyTrue) arr.push(exerciseIndex + 1);
    });
    return arr;
}
