

import { defineComponent } from 'vue';
import {IonCheckbox} from "@ionic/vue";

export default defineComponent({
  name: 'AnswerCardListHeader',
  components: {IonCheckbox},
  props: {
    isMagnifierRowVisible: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    allCardsChecked: {
      type: Boolean,
      default: false
    },
    cardGroupHash: {
      type: String,
      default: null
    }
  }
})
