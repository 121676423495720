
import {defineComponent} from "vue";

export default defineComponent({
  name: 'StudentNumber',
  components: {},
  data() {
    return {
      focus: false,
      reference: {} as any,
    }
  },
  props: {
    studentNumber: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    itemId: {
      type: Number,
      default: 0
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    confirmStudentNumber(event: KeyboardEvent) {
      const studentNumberObject = this.reference['studentNumber' + this.itemId];
      if (event.key == "Enter" || event.code === "Enter" || event.code === "NumpadEnter"){
        studentNumberObject.blur();
        studentNumberObject.close();
      }
    },
    preventNegativeSign(event: KeyboardEvent) {
      const itemObject = this.reference['studentNumber' + this.itemId];
      if (event.key === '-' || event.key === '+' ) {
        itemObject.value = '';
      }
    },
   changeFocus() {
     this.focus = !this.focus;
     this.$nextTick(() => {
       this.reference = (this.$refs as any);
       const itemObject = this.reference['studentNumber' + this.itemId];
       itemObject.focus();
       if (itemObject.value === "0") {
         itemObject.value = "";
       }
     });
   },
    changeStudentNumber(event: Event) {
      const fieldValue = ((event.target as HTMLInputElement).value);
      this.$emit('changeStudentNumber', {value: fieldValue, index: this.index, itemId: this.itemId});
    },
    inputValue() {
      const itemObject = this.reference['studentNumber' + this.itemId];
      if (itemObject.value.length > 2) {
        itemObject.value = itemObject.value.slice(0, 2);
      }
      if (parseInt(itemObject.value) === 0) {
        itemObject.value = "";
      }
      if (itemObject.value.length > 0) {
        itemObject.value = Math.abs(itemObject.value);
      }
    },
  },

});
