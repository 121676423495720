import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "gt-scores-answer-card-list-item__no-number gt-scores-answer-card-list-item__incorrect_cell gt-scores-answer-card-list-item__student-number-cell"
}
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeFocus && _ctx.changeFocus(...args)))
    }, [
      (_ctx.studentNumber === 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('ANSWER_CARD.no_student_number')), 1))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["gt-scores-answer-card-list-item__correct-cell gt-scores-answer-card-list-item__student-number-cell", {'gt-scores-answer-card-list-item__student-number-cell-preview' : _ctx.isPreview}])
          }, _toDisplayString(_ctx.studentNumber), 3))
    ], 512), [
      [_vShow, !_ctx.focus]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("input", {
        ref: 'studentNumber' + _ctx.itemId,
        value: _ctx.studentNumber,
        type: "number",
        min: "1",
        max: "99",
        class: "student-number",
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeFocus && _ctx.changeFocus(...args))),
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeStudentNumber && _ctx.changeStudentNumber(...args))),
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.inputValue && _ctx.inputValue(...args))),
        onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.preventNegativeSign && _ctx.preventNegativeSign(...args))),
        onKeydown: _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.confirmStudentNumber && _ctx.confirmStudentNumber(...args)), ["enter"]))
      }, null, 40, _hoisted_2)
    ], 512), [
      [_vShow, _ctx.focus]
    ])
  ]))
}