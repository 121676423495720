import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "gt-scores-home-answer-card-list" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_AnswerCardListItem = _resolveComponent("AnswerCardListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardList, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "gt-scores-home-answer-card-list-item",
        key: index,
        id: `listItem-${item.id}`
      }, [
        _createVNode(_component_ion_checkbox, {
          class: "gt-scores-home-answer-card-list__checkbox",
          color: "primary",
          "onUpdate:modelValue": ($event: any) => (item.isChecked = $event),
          modelValue: item.isChecked,
          mode: "md"
        }, null, 8, ["onUpdate:modelValue", "modelValue"]),
        (_ctx.answerKeys.length > 0)
          ? (_openBlock(), _createBlock(_component_AnswerCardListItem, {
              key: 0,
              "student-number": item.studentNumber,
              "score-user": item.score.scoreUser,
              "score-max": item.score.scoreMax,
              index: item.studentNumber,
              "is-changed": _ctx.isAsterixVisible(item),
              "is-preview": _ctx.isPreview,
              "item-id": item.id,
              "all-answers-marked-number": _ctx.allAnswersMarkedExerciseArr(item, _ctx.getCurrentAnswerKey(item)),
              "is-pointer-visible": _ctx.isPointerVisible,
              "is-multiple-files": _ctx.isMultipleFiles,
              "is-list-item-highlighted": _ctx.isListItemHighlighted(item),
              onOnAnswerCardModalOpen: ($event: any) => (_ctx.onAnswerCardModalOpen(item.id)),
              onChangeStudentNumber: _ctx.changeStudentNumber
            }, null, 8, ["student-number", "score-user", "score-max", "index", "is-changed", "is-preview", "item-id", "all-answers-marked-number", "is-pointer-visible", "is-multiple-files", "is-list-item-highlighted", "onOnAnswerCardModalOpen", "onChangeStudentNumber"]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}