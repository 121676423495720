
import { defineComponent, PropType } from 'vue';
import { searchOutline } from 'ionicons/icons';
import ProgressRing from '@/components/shared/ProgressRing.vue';
import StudentNumber from '@/views/AnswerCard/AnswerCardList/AnswerCardItem/StudentNumber.vue';
import { getColor } from '@/utils/ColorUtils';

export default defineComponent({
  name: 'AnswerCardListItem',
  components: { ProgressRing, StudentNumber },
  props: {
    studentNumber: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    itemId: {
      type: Number,
      default: 0
    },
    scoreUser: {
      type: Number,
      default: 0
    },
    scoreMax: {
      type: Number,
      default: 0
    },
    isMagnifierRowVisible: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    isPointerVisible: {
      type: Boolean,
      default: true
    },
    isListItemHighlighted: {
      type: Boolean,
      default: false
    },
    isMultipleFiles: {
      type: Boolean,
      default: false
    },
    isChanged: {
      type: Boolean,
      default: false
    },
    allAnswersMarkedNumber: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },
  data() {
    return {
      isOpen: false,
      searchOutline,
    }
  },
  computed: {
    scorePercentage(): number {
      return Math.round((this.scoreUser / this.scoreMax) * 100);
    },

    scoreRingColor(): string {
      return getColor(this.scorePercentage);
    },
  },
  methods: {
    changeStudentNumber(event: Event) {
      this.$emit('changeStudentNumber', event);
    }
  }
});
