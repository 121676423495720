import { AdditionalAnswersModel } from 'gt-test/dist/types/models';

export enum EditorMode {
  DEFAULT = 'default',
  ANGIELSKI_SP = 'angielski_sp',
  ANGIELSKI_SP1_3 = 'angielski_sp1_3',
  ANGIELSKI_SPP = 'angielski_spp',
  BIOLGIA_SP = 'biologia_sp',
  BIOLGIA_SPP = 'biologia_spp',
  CHEMIA_SP = 'chemia_sp',
  CHEMIA_SPP = 'chemia_spp',
  EDB_SP = 'edb_sp',
  EDB_SPP = 'edb_spp',
  FIZYKA_SP = 'fizyka_sp',
  FIZYKA_SPP = 'fizyka_spp',
  GEOGRAFIA_SP = 'geografia_sp',
  GEOGRAFIA_SPP = 'geografia_spp',
  HISTORIA_SP = 'historia_sp',
  HISTORIA_SPP = 'historia_spp',
  MATEMATYKA_SP = 'matematyka_sp',
  MATEMATYKA_SP1_3 = 'matematyka_sp1_3',
  MATEMATYKA_SPP = 'matematyka_spp',
  NIEMIECKI_SP = 'niemiecki_sp',
  NIEMIECKI_SPP = 'niemiecki_spp',
  POLSKI_SP = 'polski_sp',
  POLSKI_SPP = 'polski_spp',
  PRZEDSIEBIORCZOSC_SPP = 'przedsiebiorczosc_spp',
  PRZYRODA_SP = 'przyroda_sp',
  PRZYRODA_SPP = 'przyroda_spp',
  TECHNIKA_SP = 'technika_sp',
  WOS_SP = 'wos_sp',
  WOS_SPP = 'wos_spp'
}

export enum AnswersType {
  CLOSED = 1,
  OPEN,
  OPEN_CLOSED,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  BOOLEAN,
  BUNDLES,
  JUSTIFICATION,
  ASSIGNMENT,
  NUMERIC,
  LIST,
  CUSTOM_LIST,
  TEXT,
  ORDER_SETTING,
}

export enum BooleanAnswerType {
  FALSE,
  TRUE,
  DS
}

export interface ExerciseAnswer {
  id: number;
  content: string;
  height: number;
  isCorrect: boolean;
  booleanAnswer: BooleanAnswerType | null;
}

export type SourceName = 'CKE' | 'Nowa Era' | 'Twoje';

export interface GtTest {
  hash: string;
  variants: ExerciseVariant[];
  testId: number;
  title: string;
}

export interface ExerciseVariant {
  exercise: ExerciseAnswerKey[];
  variantId: number;
}

export interface BundleModel {
  answers: ExerciseAnswer[];
  answersType: AnswersType;
  score: number;
}

export interface ExerciseAnswerKey {
  answers: ExerciseAnswer[] | null;
  additionalAnswers?: AdditionalAnswersModel[];
  bundles: BundleModel[] | null;
  answersType: AnswersType;
  comment: string | null;
  editor: EditorMode;
  solution: string | null;
}

export interface AdditionalAnswers extends ExerciseAnswer {
  correctAnswers?: number[];
}


