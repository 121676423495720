<template>
  <svg
      :height="radius * 2"
      :width="radius * 2"
  >
    <circle
        stroke="lightgrey"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :stroke-width="1"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
    />
    <circle
        :stroke="color"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
    />
    <text x="50%" y="50%" text-anchor="middle" fill="#4C4D55" stroke-width="0.01em" dy=".3em">{{ text }}</text>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "ProgressRing",
  props: {
    radius: Number,
    progress: Number,
    stroke: Number,
    color: String,
    text: String
  },
  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference
    };
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - this.progress / 100 * this.circumference;
    }
  }
});
</script>

<style lang="scss">
@import "ProgressRing.scss";
</style>
