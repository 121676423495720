import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "gt-answer-card-list-header__table-row" }
const _hoisted_2 = { class: "gt-answer-card-list-header__checkbox-container" }
const _hoisted_3 = {
  key: 0,
  class: "gt-answer-card-list-header__checkbox-wrapper"
}
const _hoisted_4 = {
  key: 0,
  class: "gt-answer-card-list-header__row-item--header gt-scores-score-item__row-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isMagnifierRowVisible)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('ANSWER_CARD.mark')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('ANSWER_CARD.and_delete')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isMagnifierRowVisible)
        ? (_openBlock(), _createBlock(_component_ion_checkbox, {
            key: 1,
            class: "gt-answer-card-list-header__checkbox",
            color: "danger",
            mode: "md",
            id: _ctx.cardGroupHash,
            modelValue: _ctx.allCardsChecked,
            onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCheckboxChange', {event: $event, cardGroupHash: _ctx.cardGroupHash})))
          }, null, 8, ["id", "modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(["gt-answer-card-list-header__row-item--header gt-scores-score-item__row-item", {'gt-answer-card-list-header__row-item--header-black': _ctx.isPreview}])
    }, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('ANSWER_CARD.journal_number')), 1)
    ], 2),
    _createElementVNode("span", {
      class: _normalizeClass(["gt-answer-card-list-header__row-item--header gt-scores-score-item__row-item", {'gt-answer-card-list-header__row-item--header-black': _ctx.isPreview}])
    }, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('ANSWER_CARD.points_count')), 1)
    ], 2),
    _createElementVNode("span", {
      class: _normalizeClass(["gt-answer-card-list-header__row-item--header gt-scores-score-item__row-item", {'gt-answer-card-list-header__row-item--header-black': _ctx.isPreview}])
    }, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('ANSWER_CARD.percentage_score')), 1)
    ], 2),
    (_ctx.isMagnifierRowVisible)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t('ANSWER_CARD.description')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}